import { createAsyncThunk } from "@reduxjs/toolkit";

export const sendEmail = createAsyncThunk(
    "sendEmail",
    async (data) => {
        const response = await fetch(
            `https://api.brilljust.com/send-email`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
            }
        );
        return response.json();
    }
);