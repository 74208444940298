import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
// get reducers here
import { getMmvReducer } from "./Reducers/MmvReducer";
import { productListReducer } from "./Reducers/productListReducer";
import { loginReducer } from "./Reducers/loginAuthentication";
import { getBrandReducer, getModelReducer, getVariantReducer } from "./Reducers/MmvListReducer";
import { getStateReducer, getCityReducer } from "./Reducers/StateCityReducer";
import { getWalletBalanceReducer } from "./Reducers/walletReducer";
import { purchaseReducer } from "./Reducers/purchaseReducer";
import { transactionListReducer } from "./Reducers/transactionListReducer";
import { vehicleTypeReducer } from "./Reducers/VehicleTypeReducer";
import { clientDetailsReducer ,engineValidationReducer} from "./Reducers/ClientDetailsReducer";

const rootReducer = combineReducers({
    getLoggedInStatus: loginReducer.reducer,
    getMmvDetails: getMmvReducer.reducer,
    getProductList: productListReducer.reducer,
    getMake: getBrandReducer.reducer,
    getModel: getModelReducer.reducer,
    getVariant: getVariantReducer.reducer,
    getStateList: getStateReducer.reducer,
    getCityList: getCityReducer.reducer,
    getWalletData: getWalletBalanceReducer.reducer,
    getPurchaseDetails: purchaseReducer.reducer,
    getTransactionList: transactionListReducer.reducer,
    getVehicleType: vehicleTypeReducer.reducer,
    getClientDetails: clientDetailsReducer.reducer,
    getEngineStatus:engineValidationReducer.reducer
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;

export const persistor = persistStore(store);