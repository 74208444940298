import React, { useState, useEffect } from "react";
import "./ClientAccount.css";
import userIcon from "../../../Assets/Images/profile.svg";
import myPolicy from "../../../Assets/Images/myPolicy.svg";
import logout from "../../../Assets/Images/logout.svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Layouts/Navbar/Navbar";
import Button from "../../InputField/Button/Button";
import ProfileCard from "./Profile Section/ProfileCard";
import PolicyCard from "./Policy Section/PolicyCard";
import transaction from "../../../Assets/Images/transaction.svg";
import { isLoggedInAction } from "../../../Redux/Reducers/loginAuthentication";

const ClientAccount = () => {
  // policySection profileSection requestSection settingSection
  const [ContentType, setContentType] = useState("policySection");
  const [IsUploadPolicyAttempted, setIsUploadPolicyAttempted] = useState(false); // true false
  const [NoPolicyFound, setNoPolicyFound] = useState(true); // true false
  const [ShowPolicyTypeList, setShowPolicyTypeList] = useState(false); // true false
  // navigation
  const navigate = useNavigate();
  //  dispatch
  const dispatch = useDispatch();

  let formValues = {
    fullName: "",
    phoneNumber: "",
    email: "",
    userUploadedDocument: "",
    uploadedPolicyType: "",
    uploadedPolicyNumber: "",
    uploadedPolicyInsurer: "",
    uploadedPolicyDocument: "",
    uploadedPolicyStartDate: "",
    uploadedPolicyEndDate: "",
    isKycUpdated: true,
    sendAllNotification: false,
    sendPolicyUpdates: false,
    sendPolicyRenewalReminder: false,
    sendOffers: false,
    sendReminderCalls: false,
  };

  const [FormValue, setFormValue] = useState(formValues);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [ContentType]);

  const handleInputChange = (e) => {
    const { id, value, name, checked, type } = e?.target || {};
    if (type == "checkbox") {
      if (name == "sendAllNotification" && checked == true) {
        setFormValue((prevItems) => ({
          ...prevItems,
          sendAllNotification: true,
          sendPolicyUpdates: true,
          sendPolicyRenewalReminder: true,
          sendOffers: true,
          sendReminderCalls: true,
        }));
      } else if (name == "sendAllNotification" && checked == false) {
        setFormValue((prevItems) => ({
          ...prevItems,
          sendAllNotification: false,
          sendPolicyUpdates: false,
          sendPolicyRenewalReminder: false,
          sendOffers: false,
          sendReminderCalls: false,
        }));
      } else {
        setFormValue((prevItems) => ({
          ...prevItems,
          [name]: checked,
        }));
      }
    } else {
      setFormValue((prevItems) => ({
        ...prevItems,
        [name]: value,
      }));
    }
  };

  const handleUploadedPolicyInsurer = (e) => {
    setFormValue((prevItems) => ({
      ...prevItems,
      uploadedPolicyInsurer: e.value,
    }));
  };

  let breadcrumb = [
    {
      icon: transaction,
      title: "Transactions",
      name: "policySection",
    },
    {
      icon: userIcon,
      title: "My Profile",
      name: "profileSection",
    },
    {
      icon: logout,
      title: "Logout",
      name: "logoutSection",
    },
  ];

  const handleContentReveal = (name) => {
    if (name === "logoutSection") {
      dispatch(
        isLoggedInAction({
          isLoggedIn: false,
          loginData: null,
        })
      );
      navigate("/");
    }
    setContentType(name);
  };

  var today = new Date();
  var curHr = today.getHours();
  let greetingTitle = "Hello, Good Evening!";

  if (curHr < 12) {
    greetingTitle = "Hello, Good Morning!";
  } else if (curHr < 18) {
    greetingTitle = "Hello, Good Afternoon!";
  }

  const handleUploadPolicyScreen = (product) => {
    alert("Attempted For Policy Upload");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCancelPolicyUpload = () => {
    setIsUploadPolicyAttempted(false);
  };

  const handlePopUp = () => {
    if (ShowPolicyTypeList) {
      setShowPolicyTypeList(!ShowPolicyTypeList);
    }
  };

  const addNewPolicy = () => {
    alert("Add new Policy Btn Clicked!!");
  };

  const userDetails = useSelector((state) => {
    return state?.getLoggedInStatus?.data?.loginData;
  });

  useEffect(() => {
    setFormValue((prevItems) => ({
      ...prevItems,
      fullName: userDetails.first_name + " " + userDetails.last_name,
      phoneNumber: userDetails.mobile,
      email: userDetails.email
    }))
  }, []);

  return (
    <>
      <Navbar />
      <section className="clientAccount-container" onClick={handlePopUp}>
        <div className="clientAccount-parent maxWidth">
          <div className="content-container-profile">
            <div className="left-content-container-profile">
              <div className="breadCrumb-container-profile-section">
                {breadcrumb.map((data, key) => (
                  <div
                    className={
                      ContentType === data.name
                        ? "breadCrumb-profilesection breadCrumb-profilesection-active "
                        : "breadCrumb-profilesection"
                    }
                    key={key}
                    onClick={(e) => handleContentReveal(data.name)}
                  >
                    <div className="icon-profile-breadcrumb">
                      <img src={data.icon} alt="" srcSet="" />
                    </div>
                    <p>{data.title}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="right-content-container-profile">           
              {ContentType === "profileSection" && (
                <ProfileCard
                  FormValue={FormValue}
                  handleInputChange={handleInputChange}
                />
              )}

              {ContentType === "policySection" && (
                <PolicyCard
                  FormValue={FormValue}
                  handleInputChange={handleInputChange}
                  NoPolicyFound={NoPolicyFound}
                  IsUploadPolicyAttempted={IsUploadPolicyAttempted}
                  handleUploadPolicy={handleUploadPolicyScreen}
                  ShowPolicyTypeList={ShowPolicyTypeList}
                  setShowPolicyTypeList={setShowPolicyTypeList}
                  handleUploadedPolicyInsurer={handleUploadedPolicyInsurer}
                  handleCancelPolicyUpload={handleCancelPolicyUpload}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientAccount;
